import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const Adventures = ({ data }) => {
  const adventures = data.allContentfulAdventure.edges;
  return (
    <Layout>
      <SEO title="Adventures" />
      <h1>{"Here's a list of all adventures!"}</h1>
      <div className="adventures">
        {adventures.map(({ node: adventure }) => (
          <div key={adventure.id}>
            <Link to={`/adventure/${adventure.slug}`}>{adventure.title}</Link>
            <img alt={''} src={adventure.image.file.url} />
          </div>
        ))}
        <span className="" />
        <Link to="/">Go back to the homepage</Link>
      </div>
    </Layout>
  )
}

export default Adventures;

export const query = graphql`
  query AdventuresPageQuery {
    allContentfulAdventure(limit: 1000) {
      edges {
        node {
          id
          title
          slug
          content {
            childContentfulRichText {
              html
            }
          }
          image {
            file {
              url
            }
          }
          
        }
      }
    }
  }
`;
